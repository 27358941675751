<template>
    <Search v-if="isMobile()"/>
    <SearchPc v-if="!isMobile()"/>
</template>

<script>
import Search from './components/Search.vue'
import SearchPc from './components/SearchPc.vue'

export default {
  name: 'App',
  data () {
    return {
      isMobile () {
        return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      }
    }
  },
  components: {
    Search,
    SearchPc
  },
  created () {
    document.title = '乐谱大全'
    //防止某些图片不允许盗图
    let oMeta = document.createElement('meta');
    oMeta.content = 'no-referrer';
    oMeta.name = 'referrer';
    document.getElementsByTagName('head')[0].appendChild(oMeta);
  }
}
</script>

<style>
  * {
    box-sizing: border-box;
  }
  body, html {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
</style>

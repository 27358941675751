<template>
  <div id="container">
    <div class="search bar7">
      <p class="result_p">{{resultText}}</p>
      <input type="text" placeholder="请输入乐曲名称查询" v-model="searchKey" @keyup.enter="doSearch"/>
      <button id="btn_search" @Click="doSearch"></button>
    </div>
    <div class = "sheet_contents">
      <ul class="preview_list" id="preview_list">
        <li v-for="(item, idx) in computedSheetData" :key="item.no">
          <div>{{item.no}}</div>
          <img v-if="item.containsImg" v-bind:src=item.sheetImg.imgUrl @mouseenter="showLarge(item.containsImg, $event)" @mouseleave="showLarge(item.containsImg, $event)" @error="onImgLoadErr(idx)"/>
          <span v-if="!item.containsImg" class="no_prv_hint">暂无预览</span>
        </li>
      </ul>
    </div>
  </div>
  <div :style="imgLargeStyle" class="img_large" v-show="largeImgSrc.length > 0">
    <img v-bind:src=largeImgSrc />
  </div>
</template>

<script>
import qs from 'qs'

export default {
  name: 'SearchPc',
  data () {
    return {
      sheetData: [],
      errorImgNo:[],
      searchLimit: false,
      searchLimitTimer:null,
      searchKey: '',
      resultText: '',
      largeImgSrc: '',
      repoterTimer: null,
      imgLargeStyle: {
        left:0,
        right:''
      }
    }
  },
  computed: {
    computedSheetData:function() {
      return this.sheetData;
    }
  },
  methods: {
    doSearch() {
      //限制每秒查询次数
      if(this.searchLimit) {
        return;
      }
      this.sheetData = []
      if(this.searchKey.trim() == '') {
        this.resultText = ''
        return;
      }
      this.searchLimit = true
      this.axios.post('/sheet/search', qs.stringify({n:this.searchKey.trim()})).then((response) => {
      this.searchLimitTimer = setTimeout(this.searchTimeoutAction, 1000)
        this.sheetData = response.data
        for(let idx in this.sheetData) {
          let curSheet = this.sheetData[idx]
          curSheet.containsImg = true
          if(curSheet.sheetImg === null || curSheet.sheetImg.imgUrl === null || curSheet.sheetImg.imgUrl === ''){
            curSheet.sheetImg = {imgUrl: ''}
          }
        }
        let sheetChilds = document.getElementById('preview_list').children;
        if(sheetChilds.length > 1) {
          // document.getElementById('preview_list').scrollIntoView({ block: 'start', behavior: 'smooth' })
          document.getElementById('preview_list').children[0].scrollIntoView({ behavior: 'smooth' })
        }
        if(this.sheetData.length == 0) {
          this.resultText = '没有找到匹配的乐谱, 建议换关键词重试'
        } else {
          this.resultText = '共找到' + this.sheetData.length + '份乐谱, 仅显示预览'
        }
      }).catch(function(){
        this.searchLimit = false
      })
    }, 
    showLarge(elContainsImg, event) {
      if(!elContainsImg) {
        this.largeImgSrc = ''
        return
      }
      if(event.type === 'mouseenter') {
        this.largeImgSrc = event.target.currentSrc
        if(event.clientX > window.innerWidth / 2){
          //当鼠标在右半边
          this.imgLargeStyle.left = 0
          this.imgLargeStyle.right = ''
        } else {
          //当鼠标在左半边
          this.imgLargeStyle.left = ''
          this.imgLargeStyle.right = 0
        }
      } else {
        this.largeImgSrc = ''
      }
    },
    onImgLoadErr(elIdx) {
      this.sheetData[elIdx].containsImg = false
      // this.sheetData[elIdx].sheetImg.imgUrl= require('@/assets/nopreview.jpg')
      this.errorImgNo.push(this.sheetData[elIdx].no)
    },
    reportAction() {
      let _me = this;
      if(_me.errorImgNo.length == 0) {
        return
      }
      this.axios.post('/sheet/report', this.errorImgNo).then(() => {
        _me.errorImgNo.length = 0
      })
    },
    searchTimeoutAction() {
      this.searchLimit = false
    }
  },
  created(){
    this.repoterTimer = setInterval(this.reportAction, 5000);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .img_large{
    position: absolute;
    top: 0;
    max-width: 50vw;
    height: 100vh;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow: 0 0 15px 0px #6e6e6e;
  }
  .img_large img{
    max-width: 50vw;
    height: 100%;
  }
  .result_p{
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 9em;
  }
  #container {
    background: #ededed;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  div.search {
    width: 35vw;
    position: relative;
    flex-grow: 1;
  }
  input, button {
    border: none;
    outline: none;
  }
  button {
    height: 3.5em;
    width: 3.5em;
    cursor: pointer;
    position: absolute;
    bottom: 1.5em;
  }
  .bar7 input {
    border-radius: 3.5em;
    border: 2px solid #db0000;
    background: none;
    transition: .3s linear;
    padding-left: 13px;
    height:  3.5em;
    width: 100%;
    float: right;
    position: absolute;
    bottom: 1.5em;
  }
  .bar7 input:focus {
    box-shadow: 0 0 6px 0px #ff6969;
  }
  .bar7 button {
    background: none;
    right: 0;
  }
  .bar7 button:before{
    content: "\f002";
    font-family: FontAwesome;
    color: #db0000;
    font-size: 1.2em;
  }
  .sheet_contents{
    flex-grow: 2;
    width: 100vw;
    height: 0;
    /* overflow-y: scroll; */
  }
  .preview_list{
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    margin: 0;
    padding: 0 2em 0 2em;
    overflow-y: scroll;
  }
  .preview_list li{
    display: flex;
    height: 20em;
    flex-shrink: 0;
    flex-basis: 15em;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #fbfbfb;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 1px 1px 5px 0 #c8c8c8;
    margin-left: 1em;
    margin-bottom: 1em;
  }
  .preview_list li img{
    height: 100%;
    cursor: pointer;
  }
  .preview_list li div:first-child {
    position: absolute;
    background: rgb(0 0 0 / 41%);
    color: white;
    bottom: 0;
    padding: 5px 5px;
    width: 100%;
    overflow-wrap: anywhere;
    box-shadow: 0 -2px 6px 0px rgb(0 0 0 / 16%);
    font-size: 0.9em;
  }
  .no_prv_hint {
    font-size: 2em;
    color: #b1b1b1;
  }
</style>

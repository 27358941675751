<template>
  <div id="container">
    <div class = "sheet_contents">
      <ul class="preview_list" id="preview_list">
        <li v-for="(item, idx) in sheetData" :key="item.no">
          <div>
            <span>编号 : </span>
            <span>{{item.no}}</span>
          </div>
          <img v-if="item.containsImg" v-bind:src=item.sheetImg.imgUrl @error="onImgLoadErr(idx)"/>
          <span v-if="!item.containsImg" class="no_prv_hint">暂无预览</span>
        </li>
      </ul>
    </div>
    <div class="search bar7">
      <input type="text" placeholder="请输入乐曲名称查询" v-model="searchKey" @keyup.enter="doSearch"/>
      <button id="btn_search" @Click="doSearch"></button>
    </div>
  </div>
</template>

<script>
import qs from 'qs'

export default {
  name: 'Search',
  data () {
    return {
      sheetData: null,
      errorImgNo:[],
      searchLimit: false,
      searchLimitTimer:null,
      repoterTimer: null,
      searchKey: ''
    }
  },
  methods: {
    doSearch() {
      //限制每秒查询次数
      if(this.searchLimit) {
        return;
      }
      this.searchLimit = true
      this.axios.post('/sheet/search', qs.stringify({n:this.searchKey})).then((response) => {
        this.searchLimitTimer = setTimeout(this.searchTimeoutAction, 1000)
        this.sheetData = response.data
        for(let idx in this.sheetData) {
          let curSheet = this.sheetData[idx]
          curSheet.containsImg = true
          if(curSheet.sheetImg === null || curSheet.sheetImg.imgUrl === null || curSheet.sheetImg.imgUrl === ''){
            curSheet.sheetImg = {imgUrl: ''}
          }
        }
        let sheetChilds = document.getElementById('preview_list').children;
        if(sheetChilds.length > 1) {
          // document.getElementById('preview_list').scrollIntoView({ block: 'start', behavior: 'smooth' })
          document.getElementById('preview_list').children[0].scrollIntoView({ behavior: 'smooth' })
        }
        if(this.sheetData.length == 0) {
          document.title = '没有找到匹配的乐谱, 建议换关键词重试'
        } else {
          document.title = '共找到' + this.sheetData.length + '份乐谱, 仅显示预览'
        }
      }).catch(function(){
        this.searchLimit = false
      })
    },
    onImgLoadErr(elIdx) {
      this.sheetData[elIdx].containsImg = false
      // this.sheetData[elIdx].sheetImg.imgUrl= require('@/assets/nopreview.jpg')
      this.errorImgNo.push(this.sheetData[elIdx].no)
    },
    reportAction() {
      let _me = this;
      if(_me.errorImgNo.length == 0) {
        return
      }
      this.axios.post('/sheet/report', this.errorImgNo).then(() => {
        _me.errorImgNo.length = 0
      })
    },
    searchTimeoutAction() {
      this.searchLimit = false
    }
  },
  created(){
    this.repoterTimer = setInterval(this.reportAction, 5000);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #container {
    background: black;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  div.search {
    padding: 0 0 1.5em 0;
    width: 90vw;
    position: relative;
  }
  input, button {
    border: none;
    outline: none;
  }
  button {
    height: 3.5em;
    width: 3.5em;
    cursor: pointer;
    position: absolute;
  }
  .bar7 input {
    color:white;
    border-radius: 3.5em;
    /*border: 2px solid #324B4E;*/
    border: 2px solid #db0000;
    /*background: #F9F0DA;*/
    background: none;
    transition: .3s linear;
    padding-left: 13px;
    height:  3.5em;
    width: 100%;
    float: right;
  }
  .bar7 button {
    background: none;
    right: 0;
  }
  .bar7 button:before{
    content: "\f002";
    font-family: FontAwesome;
    color: #db0000;
    font-size: 1.2em;
  }
  .sheet_contents{
    flex-grow: 1;
    width: 100vw;
    /* overflow-x: scroll; */
  }
  .preview_list{
    list-style: none ;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: scroll;
  }
  .preview_list li{
    display: flex;
    height: 100%;
    flex-shrink: 0;
    flex-basis: 85vw;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    background: black;
    overflow: hidden;
  }
  .preview_list li img{
    width: 95%;
  }
  .preview_list li div {
    position: absolute;
    color: white;
    bottom: 10px;
    padding: 5px 5px;
    width: 95%;
    overflow-wrap: anywhere;
    font-size: 0.9em;
    padding-bottom: 5%;
  }
  .preview_list li div span:first-child{
    white-space: nowrap;
  }
  .preview_list li:last-child{
    margin-right: 5%;
  }  
  .no_prv_hint {
    font-size: 2em;
    color: #b1b1b1;
    display: block;
    width: 95%;
    height: 65vh;
    /* max-height: 420px; */
    padding-top: 56%;
    text-align: center;
    background: #fbfbfb;
  }
</style>
